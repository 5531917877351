import {
	Box,
	Button,
	Checkbox,
	CheckboxGroup,
	Container,
	Flex,
	FormControl,
	FormHelperText,
	FormLabel,
	Grid,
	GridItem,
	Heading,
	IconButton,
	Image,
	SimpleGrid,
	Stack,
	Text,
	useDisclosure,
} from "@chakra-ui/react";
import {
	type GroupData,
	type QueueData,
	resolveQueueNumberWithPrefix,
} from "@flowby/firebase";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoEllipsisVerticalSharp, IoVolumeHighSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import ModalContainer from "./shared/ModalContainer";
import QueueStatusBadge from "./shared/QueueStatusBadge";
import UpdateValueAnimation from "./shared/UpdateValueAnimation";

export default function Status({
	store,
	selectedQueuesData,
	allQueuesData,
	groupsData,
}: {
	store: string;
	selectedQueuesData: QueueData[];
	allQueuesData: QueueData[];
	groupsData: GroupData[] | undefined;
}) {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [logoError, setLogoError] = useState(false);
	const [prevNumbersSignature, setPrevNumbersSignature] = useState<number>(0);
	const [chosenQueueList, setChosenQueueList] = useState<string[]>([]);
	// used to not play sound on first render
	const [disableNextSound, setDisableNextSound] = useState(true);
	const statusOptionsDisclosure = useDisclosure();
	const applyChangesToUrl = () => {
		navigate({ search: `?queues=${chosenQueueList.join("|")}` });
		statusOptionsDisclosure.onClose();
	};
	const testSound = () => {
		if (audioRef?.current) {
			audioRef.current
				.play()
				.catch(() => console.log("autoplay audio not allowed"));
		}
	};

	const audioRef = useRef<HTMLAudioElement>(null);

	// only trigger sound on change of the current number of any of the queues
	const currentNumbersSignature = useMemo(
		() =>
			selectedQueuesData.reduce(
				(prev, curr) => prev + curr.state.currentNumber,
				0,
			),
		[selectedQueuesData],
	);

	useEffect(() => {
		if (prevNumbersSignature < currentNumbersSignature) {
			if (audioRef?.current && !disableNextSound) {
				// TODO: figure out what this typescript error is about
				audioRef.current
					.play()
					.catch(() => console.log("autoplay audio not allowed"));
			} else {
				setDisableNextSound(false);
			}
		}
		setPrevNumbersSignature(currentNumbersSignature);
	}, [currentNumbersSignature]);

	const showQueues = selectedQueuesData.filter(
		(q) => q.state.status !== "closed",
	);

	return (
		<>
			<Flex direction="column" minH="90vh">
				<audio ref={audioRef}>
					<source
						src="/resources/household_door_bell_ding_dong_single.mp3"
						type="audio/mpeg"
					/>
					Your browser does not support the audio element.
				</audio>
				<Box position="absolute" right={0} pt={2} pr={2}>
					<IconButton
						data-testid="status-options-button"
						variant="ghost"
						aria-label="options"
						icon={<IoEllipsisVerticalSharp fontSize={24} />}
						onClick={statusOptionsDisclosure.onOpen}
					/>
				</Box>
				<ModalContainer
					size="lg"
					isOpen={statusOptionsDisclosure.isOpen}
					onClose={statusOptionsDisclosure.onClose}
					header={"Status Options"}
					content={
						<Stack>
							<FormControl id="testSound">
								<FormLabel>Test Sound</FormLabel>
								<FormHelperText>
									When the next customer is taken in one of the queues shown on
									the status page a bell sound will be played. Click the button
									below to make sure you can hear the sound.
								</FormHelperText>
								<Button
									onClick={testSound}
									leftIcon={<IoVolumeHighSharp />}
									variant="outline"
									size="sm"
								>
									Test Sound
								</Button>
							</FormControl>
							<FormControl id="queueFilter">
								<FormLabel>Filter</FormLabel>
								<FormHelperText>
									Choose which queues you want to display on this status page.
									Your choice will only affect this browser session, other
									status pages in your store will not be affected.
								</FormHelperText>
								<CheckboxGroup
									onChange={(queueList: string[]) => {
										setChosenQueueList(queueList);
										// to not play sound when queues are added to the list
										// which it otherwise will since it increases current number signature
										if (queueList.length > selectedQueuesData.length) {
											setDisableNextSound(true);
										}
									}}
									colorScheme="green"
									defaultValue={selectedQueuesData.map((q) => q.shortName)}
								>
									<SimpleGrid columns={2} spacing={2}>
										{allQueuesData.map((q) => {
											return (
												<Checkbox
													data-testid={`status-checkbox-${q.shortName}`}
													key={q.shortName}
													value={q.shortName}
												>
													{q.displayName}
												</Checkbox>
											);
										})}
									</SimpleGrid>
								</CheckboxGroup>
							</FormControl>
						</Stack>
					}
					footer={
						<Flex justifyContent="flex-end">
							<Button
								variant="outline"
								mr={2}
								onClick={statusOptionsDisclosure.onClose}
							>
								Close
							</Button>
							<Button
								data-testid="status-apply-button"
								onClick={applyChangesToUrl}
							>
								Apply
							</Button>
						</Flex>
					}
				/>
				<Container maxW="container.lg" data-testid="status-container">
					<Stack spacing={8} py={{ base: 4, md: 12 }}>
						<Stack textAlign="center">
							{logoError ? (
								<></>
							) : (
								<Box
									position="relative"
									w="100%"
									h={70}
									textAlign="center"
									alignItems="center"
								>
									{!logoError && (
										<Image
											role="presentation"
											maxH={82}
											margin="auto"
											fit="contain"
											src={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_ENV === "dev" ? "flowby-io-dev" : "flowby-io"}.appspot.com/o/stores%2F${store}%2Flogo.png?alt=media`}
											onError={() => setLogoError(true)}
										/>
									)}
								</Box>
							)}
						</Stack>
						{showQueues.length > 0 && (
							<Stack data-testid="status-queue-list">
								<Grid templateColumns="repeat(6, 1fr)" px={5}>
									<GridItem colSpan={4} />
									<GridItem
										display="flex"
										colSpan={2}
										justifyContent="center"
										alignItems="center"
									>
										<Flex flex={1} justify="space-around">
											<Box fontWeight={600} fontSize="2xl" textAlign="center">
												{t("now-serving")}
											</Box>
											<Box fontWeight={600} fontSize="2xl" textAlign="center">
												{t("numbers-in-queue")}
											</Box>
										</Flex>
									</GridItem>
								</Grid>
								{showQueues.map((q) => {
									return (
										<Box
											data-testid={`status-queue-item-${q.shortName}`}
											key={q.shortName}
										>
											<Grid
												templateColumns="repeat(6, 1fr)"
												p={5}
												shadow="md"
												borderWidth="1px"
												bg="white"
												borderRadius="0.375rem"
												_hover={{ textDecoration: "none" }}
											>
												<GridItem
													display="flex"
													alignItems="center"
													colSpan={4}
												>
													<Heading size="xl">
														{q.displayName}
														<QueueStatusBadge
															status={q.state.status}
															size="sm"
														/>
													</Heading>
												</GridItem>
												{q.state.status !== "closed" && (
													<GridItem
														display="flex"
														colSpan={2}
														justifyContent="center"
														alignItems="center"
													>
														<Flex flex={1} justify="space-around">
															<Box px={6}>
																<UpdateValueAnimation
																	animateOnChange={[q.state.currentNumber]}
																>
																	<Box
																		px={4}
																		fontSize="5xl"
																		textAlign="center"
																		fontWeight={600}
																		data-testid={`status-queue-item-${q.shortName}-number`}
																	>
																		{resolveQueueNumberWithPrefix(
																			q.state.currentNumber,
																			q,
																			groupsData,
																		)}
																	</Box>
																</UpdateValueAnimation>
															</Box>
															<Box px={6}>
																<UpdateValueAnimation
																	animateOnChange={[q.state.count]}
																>
																	<Box
																		px={4}
																		fontSize="5xl"
																		textAlign="center"
																		fontWeight={600}
																	>
																		{q.state.count}
																	</Box>
																</UpdateValueAnimation>
															</Box>
														</Flex>
													</GridItem>
												)}
											</Grid>
										</Box>
									);
								})}
							</Stack>
						)}
					</Stack>
				</Container>
				{showQueues.length === 0 && (
					<Flex flex={1} direction="column" justify="center">
						<Stack pb={20} textAlign="center" spacing={6}>
							<Heading size="2xl">{t("all-queues-closed")}</Heading>
							<Text fontSize="2xl">{t("please-come-back-later")}</Text>
						</Stack>
					</Flex>
				)}
			</Flex>
			<Flex direction="column" minH="5vh" textAlign="center">
				{showQueues.length > 0 && (
					<Text fontSize="2xl">{t("scan-qr-code")}</Text>
				)}
			</Flex>
		</>
	);
}
