import "core-js";
import "proxy-polyfill";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import ConvertManualPage from "./pages/ConvertManualPage";
import GetNewQueueNumberPage from "./pages/GetNewQueueNumberPage";
import GroupKioskPage from "./pages/GroupKioskPage";
import GroupPage from "./pages/GroupPage";
import IndexPage from "./pages/IndexPage";
import NotFound from "./pages/NotFoundPage";
import QueueKioskPage from "./pages/QueueKioskPage";
import QueuePage from "./pages/QueuePage";
import StatusPage from "./pages/StatusPage";
import ThanksPage from "./pages/ThanksPage";
import "./reset.css";

console.log(`Flowby version: ${process.env.REACT_APP_VERSION}`);

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement,
);

root.render(
	<React.StrictMode>
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<App />}>
					<Route index element={<IndexPage />} />
					<Route path=":store">
						<Route path="status" element={<StatusPage />} />
						<Route path="q">
							<Route path=":queue">
								<Route index element={<GetNewQueueNumberPage />} />
								<Route path=":queuenumberid" element={<QueuePage />} />
								<Route path="thanks" element={<ThanksPage />} />
								<Route path="kiosk" element={<QueueKioskPage />} />
								<Route path="c">
									<Route path=":queuerId">
										<Route index element={<ConvertManualPage />} />
									</Route>
								</Route>
							</Route>
						</Route>
						<Route path="g">
							<Route path=":group">
								<Route index element={<GroupPage />} />
								<Route path="kiosk" element={<GroupKioskPage />} />
							</Route>
						</Route>
					</Route>
					<Route path="*" element={<NotFound />} />
				</Route>
			</Routes>
		</BrowserRouter>
	</React.StrictMode>,
);
